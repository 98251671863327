import { createSlice } from '@reduxjs/toolkit';
import axios from 'utils/axios';
import { dispatch } from 'redux_data/store';
import { getFirstAndLastDateOfWeek } from 'utils/util'; 

const [start_date , end_date] = getFirstAndLastDateOfWeek();

const slice = createSlice({
  name: 'income', // This is the name of the slice
  initialState: {
    hasError: null,
    isLoading: false,
    isLoadingDetail: false,
    datas: [],
    data: {},
    start_date: start_date, 
    end_date: end_date,
  },
  reducers: {
    noError(state) { // NO ERROR
      state.hasError = false;
    },
    unsetError(state) { // UNSET ERROR
      state.hasError = null;
    },
    hasError(state, action) { // HAS ERROR
      state.isLoading = false;
      state.hasError = JSON.stringify(action.payload);
    },
    startLoading: (state, action) => {
      state.isLoading = true;
    },
    startLoadingDetail: (state, action) => {
      state.isLoadingDetail = true;
    },
    setStartDate: (state, action) => {
      state.isLoading = false;
      state.start_date = action.payload;
    },
    setEndDate: (state, action) => {
      state.isLoading = false;
      state.end_date = action.payload;
    },
    setDatas: (state, action) => {
      state.isLoading = false;
      state.datas = action.payload;
    },
    setData: (state, action) => {
      state.isLoadingDetail = false;
      state.data = action.payload;
    },
  },
});

export const { 
    noError , 
    unsetError , 
    hasError, 
    startLoading , 
    startLoadingDetail , 
    setStartDate ,
    setEndDate ,
    setDatas , 
    setData ,
} = slice.actions;

const endpoint = "/income"

export function searchData({ data = {} , params = {} } = {data: {} , params: {}}) {
  return async (dispatch , getState) => {
    dispatch(slice.actions.startLoading());
    try {
      const state = getState();
      if(!data?.start_date){ data.start_date = state.sales.start_date} ;
      if(!data?.end_date){ data.end_date = state.sales.end_date} ;
      
      let endpointURL = `${endpoint}/search`;
      Object.keys(params).forEach(key => {
        if(endpointURL.includes("?")){
          endpointURL = `${endpointURL}&${key}=${params[key]}`;
        }else{
          endpointURL = `${endpointURL}?${key}=${params[key]}`;
        }
      })
      const response = await axios.post( endpointURL , data)?.then( resp => resp?.data);
      // console.log("searchData", response);
      if(response?.success){
        dispatch(slice.actions.setDatas(response.data));
      }else{
        dispatch(hasError(response));
      }
    } catch (error) {
      if(error?.message){
        dispatch(hasError(error.message));
      }else{
        dispatch(hasError(error));
      }
    }
  };
}

export function detailData({ data = {} , params = { "photo" : 1 } }) {
  return async (dispatch , getState) => {
    // console.log("detailData",data);
    dispatch(startLoadingDetail());
    try {
      let endpointURL = `${endpoint}/search`;
      Object.keys(params).forEach(key => {
        if(endpointURL.includes("?")){
          endpointURL = `${endpointURL}&${key}=${params[key]}`;
        }else{
          endpointURL = `${endpointURL}?${key}=${params[key]}`;
        }
      })
      const response = await axios.post( endpointURL , data )?.then( resp => resp?.data);
      // console.log("detailData", response);
      if(response?.success){
        dispatch(setData(response.data?.[0]));
      }else{
        dispatch(hasError(response));
      }
    } catch (error) {
      if(error?.message){
        dispatch(hasError(error.message));
      }else{
        dispatch(hasError(error));
      }
    }
  };
}

export function addData({formData}) {
  return async (dispatch , getState) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`${endpoint}`, formData )?.then( resp => resp?.data);
      // console.log("addData", response);
      if(response?.success){
        dispatch(noError());
        dispatch(searchData());
      }else{
        dispatch(hasError(response));
      }
    } catch (error) {
      if(error?.message){
        dispatch(hasError(error.message));
      }else{
        dispatch(hasError(error));
      }
    }
  };
}

export function editData({id, formData, encryptedPin}) {
  return async (dispatch , getState) => {
    dispatch(slice.actions.startLoading());
    try {
      if(encryptedPin){ axios.defaults.headers.common.pin = encryptedPin; }
      const response = await axios.patch(`${endpoint}/`, formData )?.then( resp => resp?.data);
      // console.log("editData", response);
      if(response?.success){
        dispatch(noError());
        dispatch(searchData());
      }else{
        dispatch(hasError(response));
      }
    } catch (error) {
      if(error?.message){
        dispatch(hasError(error.message));
      }else{
        dispatch(hasError(error));
      }
    }
  };
}

export function deleteData({ item_list , encryptedPin}) {
  return async (dispatch , getState) => {
    dispatch(slice.actions.startLoading());
    try {
      // console.log(list_id , encryptedPin);
      // console.log("object_list" , object_list);
      if(encryptedPin){ axios.defaults.headers.common.pin = encryptedPin; }
      const response = await axios.put(`${endpoint}/` , { item_list });
      if(response?.data?.success){
        dispatch(slice.actions.noError())
        dispatch(searchData());
      }else{
        dispatch(slice.actions.hasError(response?.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error.response?.data));
    }
  };
}

export function setDetail(data) {
  return async (dispatch , getState) => {
    // console.log("setDetail" , data);
    dispatch(startLoadingDetail());
    try {
      dispatch(setData(data))
    } catch (error) {
      console.log(error);
    }
  }
}

export default slice.reducer;
